
import { defineComponent, onMounted, ref } from 'vue';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { getCSSVariableValue } from '@/assets/ts/_utils';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'dashboard',
  components: {},
  data() {
    return {
      data: {},
      course: {},
      loading: true,
      tableData: [],
      load: true,
      entity_type_id: '',
      adminPermission: false,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      entity_id: "",
      userRole: "",
      institute_info_id: "",
      association_count: "",
      training_institute_count: "",
      course_count: "",
      trainer_count: "",
      assessor_count: "",
      running_batch: "",
      pending_enrollment: "",
      pending_attendance: "",
      pending_employment: "",
      pending_entity_count: "",
      pending_institute_count: "",
      pending_course_count: "",
      pending_assessor_count: "",
      pending_trainer_count: "",
      pending_map_trainer_institute_count: "",
      pending_map_trainer_course_count: "",
      pending_map_assessor_institute_count: "",
      pending_map_assessor_course_count: "",
      pending_trainee_count: "",
      pending_trainning_target_count:"",
      pending_assessment: "",
      pendingInstituteTarget: "",
      isActive: false,
      institute_cls: false,
    };
  },
  async created() {
    if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
      this.entity_id = VueCookieNext.getCookie('_entity_id');
      await this.getInstituteCount();
      this.isActive = true;
    }
    if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
      this.institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      await this.getCourseCount();
      await this.getPendingTraineeCount();
      this.institute_cls = true;
    }
    if (VueCookieNext.getCookie('_seip_entity_type') == 1004) {
      await this.getEntityCount();
    }
    this.userInfo = VueCookieNext.getCookie('_seip_user');
    this.userRole =
      this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
    if (this.userRole == 'Admin' || this.userRole == 'Chief Coordinator') {
      this.adminPermission = true;
    }
    //await this.Dashboard();
    this.entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
  },
  methods: {
    async getEntityCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=entity'
      )
        .then((response) => {
          this.association_count = response.data.data;
          this.getInstituteCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstituteCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=institute'
      )
        .then((response) => {
          this.training_institute_count = response.data.data;
          this.getCourseCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=course'
      )
        .then((response) => {
          this.course_count = response.data.data;
          this.getTrainerCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainerCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=trainer'
      )
        .then((response) => {
          this.trainer_count = response.data.data;
          this.getAssessorCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessorCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=assessor'
      )
        .then((response) => {
          this.assessor_count = response.data.data;
          this.getRunningBatchCount();
          if (VueCookieNext.getCookie('_seip_entity_type') != 1011) {
            this.getPendingEntityCount();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getRunningBatchCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=running-batch'
      )
        .then((response) => {
          this.running_batch = response.data.data;
          this.getPendingEnrollmentCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingEnrollmentCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-enrollment'
      )
        .then((response) => {
          this.pending_enrollment = response.data.data;
          this.getAttendaceCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAttendaceCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-attendance'
      )
        .then((response) => {
          this.pending_attendance = response.data.data;
          this.getPendingAssesssmentCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingAssesssmentCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-assessment'
      )
        .then((response) => {
          this.pending_assessment = response.data.data;
          this.getPendingEmploymentCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingEmploymentCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-employment'
      )
        .then((response) => {
          this.pending_employment = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingEntityCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-entity'
      )
        .then((response) => {
          this.pending_entity_count = response.data.data;
          this.getPendingInstituteCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingInstituteCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-institute'
      )
        .then((response) => {
          this.pending_institute_count = response.data.data;
          this.getPendingCourseCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingCourseCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-course'
      )
        .then((response) => {
          this.pending_course_count = response.data.data;
          this.getPendingAssessorCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingAssessorCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-assessor'
      )
        .then((response) => {
          this.pending_assessor_count = response.data.data;
          this.getPendingTrainerCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingTrainerCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-trainer'
      )
        .then((response) => {
          this.pending_trainer_count = response.data.data;
          this.getInstituteTrainerCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstituteTrainerCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=institute-trainer'
      )
        .then((response) => {
          this.pending_map_trainer_institute_count = response.data.data;
          this.getCourseTrainerCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseTrainerCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=course-trainer'
      )
        .then((response) => {
          this.pending_map_trainer_course_count = response.data.data;
          this.getInstituteAssessorCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstituteAssessorCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=institute-assessor'
      )
        .then((response) => {
          this.pending_map_assessor_institute_count = response.data.data;
          this.getCourseAssessorCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseAssessorCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=course-assessor'
      )
        .then((response) => {
          this.pending_map_assessor_course_count = response.data.data;
          this.getPendingTraineeCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingTraineeCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-trainee'
      )
        .then((response) => {
          this.pending_trainee_count = response.data.data;
          if (VueCookieNext.getCookie('_seip_entity_type') != 1011) {
            this.getTrainingTargetCount();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainingTargetCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-trainning-target'
      )
        .then((response) => {
          this.pending_trainning_target_count = response.data.data;
          this.getPendingInstituteTargetCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingInstituteTargetCount() {
      await ApiService.get(
        'dashboard/count?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_info_id +
          '&type=pending-institute-target'
      )
        .then((response) => {
          this.pendingInstituteTarget = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // async getData() {
    //   let entity_id = '';
    //   let institute_info_id = '';
    //   if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
    //     entity_id = VueCookieNext.getCookie('_entity_id');
    //     institute_info_id = VueCookieNext.getCookie('_institute_info_id');
    //   }
    //   console.log(entity_id, institute_info_id);
    //   await ApiService.get(
    //     'dashboard/dashboard?entity_id=' +
    //     entity_id +
    //     '&institute_info_id=' +
    //     institute_info_id
    //   )
    //     .then((response) => {
    //       console.log(response);
    //       this.loading = false;
    //       this.data = response.data.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
  },
});
