
import { defineComponent, onMounted } from 'vue';
import TotalCount from '@/views/DashBoard/TotalCount.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.js';
export default defineComponent({
  name: 'dashboard',
  components: {
    TotalCount,
  },
  data() {
    return {
      message: [],
      showNotification: false,
      notification_message: '' as any,
      componentKey: 0 as any,
    };
  },
  async created() {
    let day_diff = VueCookieNext.getCookie('day_diff');

    if (day_diff != null && day_diff <= 10) {
      this.notification_message = VueCookieNext.getCookie(
        'notification_message'
      );
      this.showNotification = true;
    }

    await this.getMessageData();
  },
  methods: {
    destroyMessage() {
      VueCookieNext.removeCookie('day_diff');
      VueCookieNext.removeCookie('notification_message');
      this.notification_message == '';
      this.showNotification = false;
    },
    async getMessageData() {
      await ApiService.get('configurations/day_message/active')
        .then((response) => {
          this.message = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
